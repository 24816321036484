<template>
    <div>
        <vs-button :class="getClass()" type="filled" :size="getSize()" icon="expand_more" @click="popupActive = true"></vs-button>
        <vs-popup :active.sync="popupActive" :title="''" class="btnlist" style="z-index:30008">
          <template v-for="(btn, index) in buttons">
            <vs-row :key="'btn_'+index" vs-justify="center" v-if="btn.show">
              <vs-button class="width100proz mb-2" :color="btn.color" :type="btn.type" :icon="btn.icon" :disabled="btn.disabled" @click="BtnClicked(index)">{{btn.label}}</vs-button>
            </vs-row>
          </template>
        </vs-popup>
    </div>
</template>
<script>
import codeHelper from '../../helper/staticCodeHelper';

export default {
  name: "ButtonList",
  props:['buttonArray','BtnClass','BtnSize'],
  data: function (){
    return {
      buttons:[],
      popupActive:false
    }
  },
  created(){
    if(this.IsNotNull(this.buttonArray) && !this.IsEmpty(this.buttonArray))
    {
      this.buttons = this.buttonArray;
    }
  },
  methods:{
      ...codeHelper,
      BtnClicked(index){
          this.$emit('BtnClicked',index,this.buttons[index].data);
          this.popupActive = false;
      },
      RefreshButtons(data){
        this.buttons = data;
      },
      getClass(){
        var text = "";
        if(this.IsNotNull(this.BtnClass) && !this.IsTextEmpty(this.BtnClass))
        {
          text = this.BtnClass;
        }
        return text;
      },
      getSize(){
        var size="small";
        if(this.IsNotNull(this.BtnSize) && !this.IsTextEmpty(this.BtnSize))
        {
          size = this.BtnSize;
        }
        return size;
      }
  }
};
</script>
<style >
div.btnlist > div.vs-popup {
  max-width: 250px !important;
}
div.btnlist > div.vs-popup > header{
  display: none;
}
</style>