<template>
<div>
  <vs-row vs-justify="center">
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-xs="12">
      <vs-card>
        <h2 class="card-title d-flex">{{$t('PARTNERORDER.HEADER.TITEL')}}
          <div class="ml-auto text-right">
            <vs-button  @click="LoadData()" size="small" color="primary" type="filled" icon="refresh"></vs-button>
          </div>
        </h2>
        <p class="card-subtitle">
          <span>{{$t('PARTNERORDER.HEADER.TEXT')}}</span>
        </p>
        <AddPartnerOrderDlg ref="addPartnerOrderDlg" v-on:Success="LoadData"></AddPartnerOrderDlg>
      </vs-card>
    </vs-col>
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-sm="12" vs-xs="12">
      <vs-card >
            <div class="d-flex align-items-center">
              <vs-button class="mt-3 mb-2"  type="filled" icon="add" @click="AddPartnerOrder">{{$t('PARTNERORDER.ADD')}}</vs-button>
            </div> 
            <div id="partnerorders_table" class="vs-con-loading__container" >
              <vs-table
                search
                :data="partnerorders"
                :noDataText="$t('PARTNERORDER.NO_DATA')"
                stripe
                max-items="25" 
                pagination
                hoverFlat
                :multiple="true"
                v-model="selectedPartnerOrders">

                <template slot="header">
                  <h3>
                    {{$t('PARTNERORDER.TABLE.HEADER')}}
                  </h3>
                </template>
                <template slot="thead">                               
                  <vs-th sort-key="bez">
                    {{$t('PARTNERORDER.TABLE.COL1')}}
                  </vs-th>
                  <vs-th sort-key="order_nr">
                    {{$t('PARTNERORDER.TABLE.COL2')}}
                  </vs-th>
                  <vs-th sort-key="state">
                    {{$t('PARTNERORDER.TABLE.COL3')}}
                  </vs-th>
                  <vs-th sort-key="base_company_fk">
                    {{$t('PARTNERORDER.TABLE.COL4')}}
                  </vs-th>                                                                                                            
                  <vs-th></vs-th>                                                                                                                                             
                </template>

                <template slot-scope="{data}">
                  <vs-tr :data="tr"  :key="indextr" v-for="(tr, indextr) in data" :class="tr.confirmation != null? 'confirmable':'n_confirmable'">   
                    <vs-td :data="tr.bez">
                      {{tr.bez}}
                    </vs-td>                  
                    <vs-td :data="tr.order_nr">
                      {{tr.order_nr}}
                    </vs-td>     
                    <vs-td :data="tr.state">
                      <vs-chip :color="GetCommonStateColor(tr.state)">
                        <vs-avatar icon="lock" color="black" v-show="tr.lockedByUserGuid.length > 0"/>{{GetCommonStateText(tr.state)}}
                        </vs-chip>
                    </vs-td>                                                                                                
                    <vs-td :data="tr.base_company_fk">
                      {{GetNameOfCompanyFromOrderPossible(tr.base_company_fk)}}
                    </vs-td> 
                    <vs-td>
                      <ButtonList :key="'btnlist'+tr.id" :buttonArray="[{label:$t('PARTNERORDER.SHOW_PARTNERORDER'),color:'primary',type:'filled',icon:'open_in_new',show:true,disabled:!isEditPartnerOrderAllowed(tr.state), data:[tr]},
                                                 {label:$t('PARTNERORDER.EDIT_PARTNERORDER'),color:'primary',type:'filled',icon:'edit',show:true, disabled:!isEditPartnerOrderAllowed(tr.state), data:[tr]},
                                                 {label:$t('PARTNERORDER.DELETE_PARTNERORDER'),color:'primary',type:'filled',icon:'delete',show:true,disabled:!isEditPartnerOrderAllowed(tr.state), data:[tr.id,tr.bez]}
                                                ]" v-on:BtnClicked="ButtonListClicked"></ButtonList>

                    </vs-td>                    
                                                                                                                                                                                              
                  </vs-tr>
                </template>
              </vs-table>
            </div> 
            <vs-button :disabled="!mergePossible" @click="OpenConfirmMergePartnerOrder">{{$t('PARTNERORDER.BTN_MERGE')}}</vs-button>    
            <vs-prompt
              @cancel="CanceldDlgRejectOffer"
              @accept="AcceptedDlgRejectOffer"
              @close="CanceldDlgRejectOffer"
              :active.sync="dlgRejectOfferActive"
              :title="$t('COM.SURE.TITEL')"
              :accept-text="$t('COM.SAVE')"
              :cancel-text="$t('COM.CANCEL')"
              id="promptRejectPartnerOrder"
              :is-valid="dlgRejectNote.length > 0">
              <div>
                <div>
                  <p>{{$t('PARTNERORDER.OFFER.REJECT.TEXT')}}</p>
                  <div class="default-input d-flex align-items-center">
                    <vs-input ref="bez" :label="$t('PARTNERORDER.OFFER.REJECT.NOTE')"  class="float-left mr-2 inputx" :placeholder="$t('PARTNERORDER.OFFER.REJECT.PLACEHOLDER.NOTE')"  v-model="dlgRejectNote" :danger="dlgRejectNote.length <= 0" val-icon-danger="clear"/>
                  </div>
                </div>
              </div>
            </vs-prompt>      
        </vs-card>
        <vs-card>
          <div id="partnerorders_sent_table" class="vs-con-loading__container" >
              <vs-table
                search
                :data="partnerorders_sent"
                :noDataText="$t('PARTNERORDER.NO_DATA')"
                stripe
                max-items="25" 
                pagination
                hoverFlat>

                <template slot="header">
                  <h3>
                    {{$t('PARTNERORDER.TABLE_SENT.HEADER')}}
                  </h3>
                </template>
                <template slot="thead">                               
                  <vs-th sort-key="bez">
                    {{$t('PARTNERORDER.TABLE.COL1')}}
                  </vs-th>
                  <vs-th sort-key="order_nr">
                    {{$t('PARTNERORDER.TABLE.COL2')}}
                  </vs-th>
                  <vs-th sort-key="state">
                    {{$t('PARTNERORDER.TABLE.COL3')}}
                  </vs-th>
                  <vs-th sort-key="base_company_fk">
                    {{$t('PARTNERORDER.TABLE.COL4')}}
                  </vs-th>                                                                                                            
                  <vs-th></vs-th>                                                                                                                                             
                </template>

                <template slot-scope="{data}">
                  <vs-tr :data="tr"  :key="indextr" v-for="(tr, indextr) in data" :class="tr.confirmation != null? 'confirmable':'n_confirmable'">   
                    <vs-td :data="tr.bez">
                      {{tr.bez}}
                    </vs-td>                  
                    <vs-td :data="tr.order_nr">
                      {{tr.order_nr}}
                    </vs-td>     
                    <vs-td :data="tr.state">
                      <vs-chip :color="GetCommonStateColor(tr.state)">
                        <vs-avatar icon="lock" color="black" v-show="tr.lockedByUserGuid.length > 0"/>{{GetCommonStateText(tr.state)}}
                        </vs-chip>
                    </vs-td>                                                                                                
                    <vs-td :data="tr.base_company_fk">
                      {{GetNameOfCompanyFromOrderPossible(tr.base_company_fk)}}
                    </vs-td> 
                    <vs-td>
                      <ButtonList :key="'btnlist'+tr.id" :buttonArray="[{label:$t('PARTNERORDER.SHOW_PARTNERORDER_OFFER'),color:'primary',type:'filled',icon:'open_in_new',show:true,disabled:tr.offer_fk <= 0, data:[tr.id,tr.offer_fk]},
                                                 {label:$t('PARTNERORDER.CONFIRM_PARTNERORDER_OFFER'),color:'success',type:'filled',icon:'check',show:true,disabled:!tr.confirmation_possible, data:[tr,true]},
                                                 {label:$t('PARTNERORDER.REJECT_PARTNERORDER_OFFER'),color:'danger',type:'filled',icon:'clear',show:true,disabled:!tr.confirmation_possible, data:[tr,false]},
                                                ]" v-on:BtnClicked="ButtonListSentClicked"></ButtonList>

                    </vs-td>                    
                                                                                                                                                                                              
                  </vs-tr>
                </template>
              </vs-table>
            </div> 
        </vs-card>
      </vs-col>
  </vs-row>

</div>
</template>

<script>

import helperMethods from '../helper/staticFuncHelper';
import enumHelper from '../helper/enumHelper';
import codeHelper from '../helper/staticCodeHelper';
import AddPartnerOrderDlg from './components/dialogs/AddPartnerOrderDlg.vue';
import ButtonList from './components/ButtonList.vue';
import {commonStates} from '../helper/enumHelper';

export default {
  name: "PartnerOrders",
  components: {
    AddPartnerOrderDlg,
    ButtonList
  },
    data: function (){
      return {
      partnerorders:[],
      partnerorders_sent:[],
      dlgRejectOfferActive:false,
      dlgRejectNote:"",
      actPartnerOrderData:null,
      selectedPartnerOrders:[]
      };
  },
  mounted () {
      this.LoadData();
    },
  computed: 
  { 
    getTableData() {
      var data = [];
      if(this.$store.state.partnerorder.partnerorders.data != null)
      {
        data = this.$store.state.partnerorder.partnerorders.data
      }
      return data;
    },
    loadingTableData () {
      var loading = false;
      if(typeof(this.$store.state.partnerorder.partnerorders.status) != 'undefined')
        loading = this.$store.state.partnerorder.partnerorders.status.loading;
      return loading;
    },
    mergePossible(){
      return this.selectedPartnerOrders.length > 1;
    }
  },
  watch: {
      getTableData(value) {
          this.partnerorders = [];
          this.partnerorders_sent = [];

          value.forEach(order => {
            if(order.state != commonStates.CREATED && order.state != commonStates.INWORK )
            {
              this.partnerorders_sent.push(order);
            }
            else
            {
              this.partnerorders.push(order);
            }
          });
      },
      loadingTableData(value)
      {
        if(value)
        {
          this.$store.dispatch('alertqueue/showLoader', {'id':'#partnerorders_table'});
        }
        else
        {
          this.$store.dispatch('alertqueue/hideLoader', {'id':'#partnerorders_table'});
        }
      },
    },
  methods: {    
      ...helperMethods,
      ...enumHelper,
      ...codeHelper,
      LoadData: function ()
      {
          this.$store.dispatch('partnerorder/getPartnerOrders'); 
          this.$store.dispatch('company/getCompaniesOrderPossible'); 
      },
      MergePartnerOrders(){
        var data = {partnerorder_fks:[]}
        this.selectedPartnerOrders.forEach(order => {
          data.partnerorder_fks.push(order.id);
        });

        this.$store.dispatch('partnerorder/mergePartnerOrder', data)
        .then(response => {
          if(response.success == true)
          {
            this.LoadData();
            this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('PARTNERORDER.SUCCESS.MERGE')});  
          }
          else
          {
            this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':this.$t('PARTNERORDER.WARNING.MERGE').replace("*1*",this.$t(response.text))}); 
          }
        }, 
        error =>{

            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('PARTNERORDER.ERROR.MERGE').replace("*1*",error)});  
        });

      },
      ButtonListClicked(index, data){
        switch(index)
        {
          case 0:
            this.ShowPartnerOrder(data[0]);
            break;
          case 1:
            this.EditPartnerOrder(data[0]);
            break;
          case 2:
            this.OpenConfirmDeletePartnerOrder(data[0],data[1])
            break;                           
        }
      },
      ButtonListSentClicked(index, data){
        switch(index)
        {
          case 0:
            this.ShowPartnerOrderOffer(data[0],data[1]);
            break;  
          case 1:
            this.ConfirmPartnerOrderOffer(data[0],data[1]);
            break;
          case 2:
            this.actPartnerOrderData = data[0];
            this.dlgRejectOfferActive = true;
            break;                           
        }
      },
      AcceptedDlgRejectOffer()
      {
        this.ConfirmPartnerOrderOffer(this.actPartnerOrderData, false);
        this.actPartnerOrderData = [];
        this.dlgRejectNote = "";
      },
      CanceldDlgRejectOffer(){
        this.dlgRejectNote = "";
        this.actPartnerOrderData = [];
      },
      ShowPartnerOrderOffer(partnerorder_fk,offer_fk){
        this.$router.push({ name: 'Offer', params: { offer_fk: offer_fk, editable:false, for_partnerorder_fk:partnerorder_fk } });
      },
      ConfirmPartnerOrderOffer(data, confirm){

        var request = data.confirmation;
        request.note = this.dlgRejectNote;
        if(confirm)
        {
          request.state = commonStates.CONFIRMED;
        }
        else
        {
          request.state = commonStates.REJECTED;
        }

        this.$store.dispatch('accounting/confirmOffer', request)
        .then(response => {
          if(response.success == true)
          {
            this.LoadData();
            var text = this.$t('PARTNERORDER.SUCCESS.CONFIRM');
            if(confirm == false)
            {
              text = this.$t('PARTNERORDER.SUCCESS.REJECT');
            }

            this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':text});  
          }
          else
          {
            var text2 = this.$t('PARTNERORDER.WARNING.CONFIRM').replace("*1*",this.$t(response.text));
            if(confirm == false)
            {
              text2 = this.$t('PARTNERORDER.WARNING.REJECT').replace("*1*",this.$t(response.text));
            }
            
            this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text2}); 
          }
        }, 
        error =>{

            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('PARTNERORDER.ERROR.CONFIRM').replace("*1*",error)});  
        });
      },
      ShowPartnerOrder(data){
        var editable = this.isEditPartnerOrderAllowed(data.state);
          this.$store.dispatch('partnerorder/isPartnerOrderLocked', data.id)
          .then(resp => {
            
            var params = {id:data.id,locked:true,force:false};
            if(resp.isLocked == true)
            {
              if(this.$can('overwrite', 'admin'))
              {
                params.force = true;
              }
            }
            if((resp.isLocked == false) || (resp.isLocked == true && params.force == true))
            {
              if(editable == true)
              {
                this.$store.dispatch('partnerorder/lockPartnerOrder', params)
                .then(response => {
                  if(response.success == true)
                  {
                    this.$router.push({ name: 'PartnerOrder', params: { partnerorder_fk: data.id, editable:editable } });
                  }
                  else
                  {
                    this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.WARNING.TITEL'),'text':response.text}); 
                  }
                },
                error => {
                  var text = this.$t('PARTNERORDER.LOCK.ERROR').replace("*1*",error);
                  this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':text});  
                });
              }
              else
              {
                this.$router.push({ name: 'PartnerOrder', params: { partnerorder_fk: data.id, editable:editable } });
              }
            }
            else
            {
              var text = this.$t('PARTNERORDER.ALREADY_LOCKED').replace("{0}",resp.username);
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
            }
          }, 
          error =>{
              var text = this.$t('PARTNERORDER.LOCKED.ERROR').replace("*1*",error);
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':text});  
          });
      },
      EditPartnerOrder(data){
        this.$refs.addPartnerOrderDlg.InitDlgData(data);
      },
      AddPartnerOrder(){
        this.$refs.addPartnerOrderDlg.ShowDlg();
      },
      GetCurrencyString(amount,language_fk,currency_kuerzel){
        if(this.IsNotNull(amount) && this.IsNotNull(language_fk) && this.IsNotNull(currency_kuerzel))
        {
          return this.DecimalToString(amount,currency_kuerzel,this.GetLocalFormatOfLanguage(language_fk));
        }
        else
        {
          return "";
        }
      },   
      OpenConfirmDeletePartnerOrder(id,name) {
        this.$vs.dialog({
          type: "confirm",
          color: "primary",
          title: this.$t('COM.SURE.TITEL'),
          acceptText: this.$t('COM.DELETE'),
          cancelText:this.$t('COM.CANCEL'),
          text: this.$t('PARTNERORDER.QUESTION.DELETE').replace("*1*",name),
          accept: this.SendDeleteRequest,
          parameters:{"id": id,"name": name}
        });
      
      },
      OpenConfirmMergePartnerOrder() {
        this.$vs.dialog({
          type: "confirm",
          color: "primary",
          title: this.$t('COM.SURE.TITEL'),
          acceptText: this.$t('COM.MERGE'),
          cancelText:this.$t('COM.CANCEL'),
          text: this.$t('PARTNERORDER.QUESTION.MERGE'),
          accept: this.MergePartnerOrders
        });
      
      },
      SendDeleteRequest: function(parameters)
      {
        this.$store.dispatch('partnerorder/deletePartnerOrder', parameters.id)
        .then(response => {
          if(response.success == true)
          {
            this.LoadData();
            this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('PARTNERORDER.SUCCESS.DELETE').replace("*1*",parameters.name)});  
          }
          else
          {
            var text = this.$t('PARTNERORDER.WARNING.DELETE').replace("*1*",parameters.name);
            text = text.replace("*2*",this.$t(response.text));
            this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
          }
        }, 
        error =>{

            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('PARTNERORDER.ERROR.DELETE').replace("*1*",error)});  
        });
      }
  }
};

</script>

<style>
  .confirmable{
    font-weight: bold;
  }
</style>